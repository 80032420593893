<template>
  <div class="owl-date-range-selector-dropdown">
    <owl-button-dropdown
      :menu-alignment="menuAlignment"
      @update-menu-status="dropdownToggled"
    >
      <template
        slot="button"
        slot-scope="{ toggleMenu, closeMenu, open }"
      >
        <button
          class="tw-text-sm tw-text-gray-600 tw-select-none"
          @click="toggleMenu"
          @keydown.esc="closeMenu"
        >
          {{ buttonLabel }}
          <i
            class="fas tw-ml-1"
            :class="[open ? 'fa-caret-up' : 'fa-caret-down']"
          />
        </button>
      </template>
      <template
        slot="default"
        slot-scope="{ closeMenu, open }"
      >
        <div
          v-if="open"
          class="tw-min-w-64"
        >
          <div class="tw-flex tw-items-center tw-px-2">
            <div class="tw-text-sm">
              {{ titleLabel }}
            </div>
            <div class="tw-ml-auto">
              <button
                class="tw-py-1 tw-px-2 tw-translate-x-2"
                @click="closeMenu"
              >
                <i class="fal fa-times" />
              </button>
            </div>
          </div>
          <div class="tw-flex tw-items-center tw-p-2 tw-text-sm tw-border-b">
            <div
              class="tw-border tw-h-8 tw-flex tw-justify-center tw-items-center tw-w-full tw-text-center tw-cursor-pointer tw-select-none"
              :class="getSelectedDateCSS('start')"
              @click="openPicker('start')"
            >
              {{ inputDateLabel.start }}
            </div>
            <div class="tw-px-2">
              -
            </div>
            <div
              class="tw-border tw-h-8 tw-flex tw-justify-center tw-items-center tw-w-full tw-text-center tw-cursor-pointer tw-select-none"
              :class="getSelectedDateCSS('end')"
              @click="openPicker('end')"
            >
              {{ inputDateLabel.end }}
            </div>
          </div>
          <div v-if="isPickerVisible">
            <div class="tw-text-left tw-px-2 tw-pt-3 tw-text-sm">
              <span v-if="selectedPicker === 'start'">
                Select a <span class="tw-font-bold">start</span> date.
              </span>
              <span v-else>
                Select an <span class="tw-font-bold">end</span> date.
              </span>
            </div>
            <owl-date-picker
              class="tw-px-0.5"
              :date="pickerDate"
              :max-date="dateSelectionConstraints.maxDate"
              :min-date="dateSelectionConstraints.minDate"
              is-required
              @update="updateSelectedRange($event, selectedPicker)"
              @endDateSelected="setEndDateFocus"
            />
          </div>
          <div v-else>
            <ul>
              <li
                v-for="preset in filteredPresets"
                :key="preset.value"
              >
                <button
                  class="tw-w-full tw-text-sm tw-px-2 tw-py-1 tw-text-left hover:tw-bg-blue-100"
                  @click="preset.action(); selectedPreset = preset.value"
                >
                  <span
                    v-if="preset.value == selectedPreset"
                    class="tw-float-right tw-text-green-500"
                  >
                    <i class="fas fa-check" />
                  </span>
                  {{ preset.label }}
                </button>
              </li>
            </ul>
          </div>
          <div class="tw-flex tw-w-full tw-p-2 tw-border-t">
            <div class="tw-w-1/2 tw-pr-1">
              <owl-button
                v-if="isPickerVisible"
                is-block
                size="small"
                label=""
                @on-click="togglePicker"
              >
                <i class="fal fa-angle-left" /> Presets
              </owl-button>
            </div>
            <div class="tw-w-1/2 tw-pl-1">
              <owl-button
                is-block
                variant="primary"
                size="small"
                @on-click="applyChanges(closeMenu)"
              >
                Apply
              </owl-button>
            </div>
          </div>
        </div>
      </template>
    </owl-button-dropdown>
  </div>
</template>

<script>
import OwlButton from './Button.vue'
import OwlButtonDropdown from './ButtonDropdown.vue'
import OwlDatePicker from './DatePicker.vue'
import { format, addDays, subDays, subMonths, startOfDay, startOfMonth, endOfMonth, getUnixTime } from 'date-fns'

const beginningOfTime = new Date(1970, 0, 1, 0, 0, 0)
const today = new Date()
const yesterday = subDays(today, 1)
const thirtyDaysAgo = subDays(today, 30)
const lastMonth = subMonths(today, 1)
const twelveMonthsAgo = addDays(subMonths(yesterday, 12), 1)
const labelFormat = 'MMM d, yyyy'
const labelFormatTruncated = 'MMM d'
const workingDateFormat = 'yyyy-MM-dd'

const convertISOtoDate = (isoDate) => {
  const dateParts = isoDate.split('-')
  return new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
}

const setDate = (date) => format(date, workingDateFormat)
const setDateLabel = (date) => {
  return date ? format(convertISOtoDate(date), labelFormat) : null
}

export default {
  name: 'OwlDateRangeSelectorDropdown',
  components: {
    OwlButton,
    OwlButtonDropdown,
    OwlDatePicker
  },
  props: {
    start: {
      type: String,
      default: null
    },
    end: {
      type: String,
      default: null
    },
    showAllTime: {
      type: Boolean,
      default: true
    },
    menuAlignment: {
      type: String,
      default: 'left'
    }
  },
  data () {
    return {
      isPickerVisible: false,
      selectedPicker: null,
      selectedPreset: null,
      selectedRange: {
        start: null,
        end: null
      },
      presets: [
        {
          label: 'Last 30 days',
          value: 'last30',
          action: this.setRangeToLast30
        },
        {
          label: 'Current month',
          value: 'currentMonth',
          action: this.setRangeToCurrentMonth
        },
        {
          label: 'Last month',
          value: 'lastMonth',
          action: this.setRangeToLastMonth
        },
        {
          label: 'Last 12 months',
          value: 'last12Months',
          action: this.setRangeToLast12Months
        },
        {
          label: 'All time',
          value: 'allTime',
          action: this.setRangeToAllTime
        },
        {
          label: 'Custom',
          value: 'custom',
          action: this.setCustom
        }
      ]
    }
  },
  computed: {
    pickerDate () {
      return this.selectedPicker ? this.selectedRange[this.selectedPicker] : null
    },
    isSelectedAllTime () {
      return this.selectedRange.start === setDate(beginningOfTime) && this.selectedRange.end === setDate(yesterday)
    },
    dateSelectionConstraints () {
      return {
        minDate: this.selectedPicker === 'start' ? null : format(addDays(convertISOtoDate(this.selectedRange.start), 1), workingDateFormat),
        maxDate: this.selectedPicker === 'end' ? format(yesterday, workingDateFormat) : format(subDays(convertISOtoDate(this.selectedRange.end), 1), workingDateFormat)
      }
    },
    filteredPresets () {
      return this.showAllTime ? this.presets : this.presets.filter(preset => preset.value !== 'allTime')
    },
    buttonLabel () {
      if (this.start === '1970-01-01' || !this.start || !this.end) {
        return 'All time'
      }
      const start = convertISOtoDate(this.start)
      const end = convertISOtoDate(this.end)
      const startFormat = start.getFullYear() === end.getFullYear() ? labelFormatTruncated : labelFormat

      return `${format(start, startFormat)} - ${format(end, labelFormat)}`
    },
    titleLabel () {
      return !this.selectedPreset ? 'Select a date range...' : this.presets.filter(preset => preset.value === this.selectedPreset)[0].label
    },
    inputDateLabel () {
      return {
        start: this.selectedPreset !== 'allTime' ? setDateLabel(this.selectedRange.start) : '',
        end: this.selectedPreset !== 'allTime' ? setDateLabel(this.selectedRange.end) : ''
      }
    }
  },
  watch: {
    start: {
      immediate: true,
      handler (newValue) {
        this.selectedRange.start = newValue
      }
    },
    end: {
      immediate: true,
      handler (newValue) {
        this.selectedRange.end = newValue
      }
    }
  },
  created () {
    this.isLast30days()
  },
  methods: {
    applyChanges (action) {
      this.$emit('update', {
        ...this.selectedRange,
        selectionType: this.selectedPreset
      })
      action()
      this.isPickerVisible = false
    },
    setEndDateFocus () {
      this.openPicker('end')
    },
    isLast30days () {
      if (this.selectedRange.start === setDate(thirtyDaysAgo) && this.selectedRange.end === setDate(yesterday)) {
        this.selectedPreset = 'last30'
      }
    },
    getSelectedDateCSS (value) {
      return this.isPickerVisible && this.selectedPicker === value ? 'tw-border-2 tw-border-blue-500 tw-bg-blue-100' : ''
    },
    setRangeToCurrentMonth () {
      // if today is the first of the month, current month should be set to prev month
      const date1InMs = getUnixTime(startOfDay(today))
      const date2InMs = getUnixTime(startOfMonth(today))

      if (date1InMs === date2InMs) {
        this.selectedRange.start = setDate(startOfMonth(yesterday))
        this.selectedRange.end = setDate(yesterday)
      } else {
        this.selectedRange.start = setDate(startOfMonth(today))
        this.selectedRange.end = setDate(subDays(today, 1))
      }
    },
    setRangeToLastMonth () {
      this.selectedRange.start = setDate(startOfMonth(lastMonth))
      this.selectedRange.end = setDate(endOfMonth(lastMonth))
    },
    setRangeToLast30 () {
      this.selectedRange.start = setDate(thirtyDaysAgo)
      this.selectedRange.end = setDate(yesterday)
    },
    setRangeToLast12Months () {
      this.selectedRange.start = setDate(twelveMonthsAgo)
      this.selectedRange.end = setDate(yesterday)
    },
    setRangeToAllTime () {
      this.selectedRange.start = setDate(beginningOfTime)
      this.selectedRange.end = setDate(yesterday)
    },
    setCustom () {
      this.openPicker()
    },
    openPicker (value) {
      if (this.selectedPreset === 'allTime') {
        this.setRangeToCurrentMonth()
      }

      this.selectedPreset = 'custom'
      this.selectedPicker = !value ? 'start' : value
      this.isPickerVisible = true
    },
    togglePicker () {
      this.isPickerVisible = !this.isPickerVisible
    },
    updateSelectedRange (value, type) {
      this.selectedRange[type] = value
      this.selectedPreset = 'custom'
    },
    dropdownToggled () {
      this.isPickerVisible = false
      this.selectedRange.start = this.start
      this.selectedRange.end = this.end
    }
  }
}

</script>

<style scoped>
  .owl-date-range-selector-dropdown::v-deep .vc-container {
    border: none !important;
  }
</style>
