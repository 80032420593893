<template>
  <ul v-if="options.length > 0">
    <li
      v-for="(option, i) in options"
      :key="i"
      class="tw-w-full"
    >
      <button
        :ref="`pick-list-item-${i}`"
        class="tw-w-full tw-text-left tw-px-2 tw-py-1 hover:tw-bg-blue-50"
        @click="selectItem(option)"
        @keydown.up="focusOn(i - 1)"
        @keydown.down="focusOn(i + 1)"
      >
        <span
          v-if="option.selected"
          class="tw-w-5 tw-inline-block"
        >
          <i class="far fa-check-square" />
        </span>
        <span
          v-else
          class="tw-w-5 tw-inline-block"
        >
          <i class="far fa-square" />
        </span>
        <span>{{ option.label }}</span>
      </button>
    </li>
  </ul>
  <p v-else>
    No items to select!
  </p>
</template>

<script>
export default {
  name: 'OwlPickList',
  props: {
    selected: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    options () {
      return this.items.map(item => ({ ...item, selected: this.selected.includes(item.id) }))
    }
  },
  methods: {
    focusOn (i) {
      const item = `pick-list-item-${i}`

      if (this.$refs[item] && this.$refs[item][0]) {
        this.$refs[item][0].focus()
      }
    },
    selectItem (item) {
      const selected = [...this.selected]

      const index = selected.indexOf(item.id)

      if (index === -1) {
        selected.push(item.id)
      } else {
        selected.splice(index, 1)
      }

      this.$emit('update-selected', selected)
    }
  }
}
</script>
