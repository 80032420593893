import { format, addDays, differenceInCalendarDays, fromUnixTime, parseISO, getYear } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'

export const getTickAndGraphInterval = (days, selectionType) => {
  // default 0 to 7 days
  const settings = {
    points: 'daily',
    ticks: 'daily',
    patientUsagePoints: 'daily'
  }

  if (days > 1095) {
    settings.points = 'quarterly'
    settings.patientUsagePoints = 'quarterly'
    settings.ticks = 'semi-annually'
  } else if (days > 730) {
    settings.points = 'monthly'
    settings.patientUsagePoints = 'monthly'
    settings.ticks = 'quarterly'
  } else if (days > 365) {
    settings.points = 'monthly'
    settings.patientUsagePoints = 'monthly'
    settings.ticks = 'bi-monthly'
  } else if (days === 365) {
    settings.points = 'weekly'
    settings.patientUsagePoints = 'weekly'
    settings.ticks = 'bi-monthly'
  } else if (days > 270) {
    settings.points = 'weekly'
    settings.patientUsagePoints = 'weekly'
    settings.ticks = 'monthly'
  } else if (days >= 90) {
    settings.points = 'weekly'
    settings.patientUsagePoints = 'weekly'
    settings.ticks = 'bi-weekly'
  } else if (days > 31) {
    settings.points = 'weekly'
    settings.patientUsagePoints = 'weekly'
    settings.ticks = 'weekly'
  } else if (days > 8) {
    settings.points = 'daily'
    settings.patientUsagePoints = 'daily'
    settings.ticks = 'weekly'
  }

  if (selectionType === 'last30' || selectionType === 'lastMonth') {
    settings.patientUsagePoints = 'weekly'
  }

  return settings
}

export const getTickAndGraphIntervalFromUnix = (start, end) => {
  const days = differenceInCalendarDays(fromUnixTime(end), fromUnixTime(start)) + 1
  return getTickAndGraphInterval(days)
}

export const getTickAndGraphIntervalFromISO = (start, end, selectionType) => {
  const days = differenceInCalendarDays(parseISO(end), parseISO(start)) + 1
  return getTickAndGraphInterval(days, selectionType)
}

const getDaysFromUnix = (start, end) => {
  const days = differenceInCalendarDays(fromUnixTime(end), fromUnixTime(start)) + 1
  return days
}

const getTickIntervalInMilliseconds = (tickInterval) => {
  let days
  switch (tickInterval) {
    case 'semi-annually':
      days = 180
      break
    case 'quarterly':
      days = 90
      break
    case 'bi-monthly':
      days = 60
      break
    case 'monthly':
      days = 30
      break
    case 'bi-weekly':
      days = 15
      break
    case 'weekly':
      days = 7
      break
    case 'daily':
      days = 1
      break
  }
  return days * 86400 * 1000
}

const getTickPosition = (chartObject) => {
  const points = chartObject.series[0].processedXData
  const pointCount = points.length
  const divisor = pointCount >= 12 ? 12 : pointCount
  const tickInterval = Math.round(pointCount / divisor)
  const everyNth = (points, tickInterval) => points.filter((e, i) => i === 0 || i % tickInterval === 0)
  const ticks = everyNth(points, tickInterval)

  return ticks
}

const getColumnTickInterval = (series) => {
  const points = series.map(item => item[0])
  const pointCount = points.length
  const start = points[0]
  const end = points[pointCount - 1]
  const days = getDaysFromUnix(start / 1000, end / 1000)
  const intervalCalc = 7 * 24 * 3600 * 1000
  const tickInterval = days > 7 && days <= 30 ? intervalCalc : null

  return tickInterval
}

const getTooltipDateValue = (pointData, start, end, direction) => {
  const currentPointIndex = pointData.index !== undefined ? pointData.index : pointData.point.index
  const fromDate = direction === 'backward' ? addDays(pointData.series.data[0].series.userOptions.start_dates[currentPointIndex], 0).toISOString() : addDays(pointData.x, 0).toISOString()
  const toDate = direction === 'backward' ? addDays(pointData.x, 0).toISOString() : addDays(pointData.series.data[0].series.userOptions.end_dates[currentPointIndex], 0).toISOString()
  const fromDateFormat = seriesSpansYears(start, end) ? 'MMM d, yyyy' : 'MMM d'
  const convertedFrom = formatToTimeZone(fromDate, fromDateFormat)
  const convertedTo = formatToTimeZone(toDate, 'MMM d, yyyy')

  return fromDate === toDate ? convertedTo : `${convertedFrom} - ${convertedTo}`
}

const getStackedColumnTooltipFormat = (context, start, end) => {
  const index = context.points[0].point.index
  const contextSeries = context.points[0].point.series
  const chartSeries = contextSeries.chart.series
  const output = [
    '<div class="tw-font-semibold">' + getTooltipDateValue(context.points[0].point, start, end) + ' users:</div>'
  ]
  chartSeries.forEach((series) => {
    const item = series.data[0].series.data[index].series
    const y = series.data[index].y
    output.push(
      '<br/><span style="color:' + item.color + '">\u25CF</span>  <strong>' + y +
      '</strong><span style="font-weight:400; font-size: 14px; text-transform: lowercase"> ' + item.name + '</span>'
    )
  })

  return output
}

const getStackedColumnTooltipPosition = (context, width, height, point) => {
  const offsetLeft = width > 180 ? width / 8 : 0 // addresses a quirk with tt positioning for different width callouts
  return { x: point.plotX - offsetLeft, y: point.plotY - 36 }
}

const getStackedBarTooltipFormat = (context) => {
  const index = context.point.index
  const contextSeries = context.series
  const chartSeries = contextSeries.chart.series
  const output = []
  chartSeries.forEach((series) => {
    const value = Object.prototype.hasOwnProperty.call(series.data[index], 'percentage') && series.data[index].percentage !== undefined ? series.data[index].percentage : null
    if (value !== null) {
      output.push(
        '<span style="font-weight:400; font-size: 14px;">' + value + '% completed.</span>'
      )
    }
  })
  return output
}

const getStackedBarTooltipPosition = (context, width, height, point) => {
  const groupPoints = context.chart.series.map(s => s.data[context.chart.hoverPoint.index])
  const plotWidth = context.chart.plotSizeY
  const maxY = Math.max(...groupPoints.map(x => x.y))
  const maxPoint = groupPoints.find(x => x.y === maxY)
  const maxPointAnchor = context.getAnchor(maxPoint)
  let negative = false

  // place to left or right?
  if (maxPointAnchor[2] + width > plotWidth) {
    negative = true
  }

  return context.getPosition(width, height, {
    plotX: maxPointAnchor[0],
    plotY: maxPointAnchor[1],
    negative: negative,
    h: maxPointAnchor[2]
  })
}

const formatToTimeZone = (date, formatStr) => {
  const [year, month, day] = date.substr(0, 10).split('-')
  return format(new Date(
    year,
    (month - 1),
    day
  ), formatStr)
}

const getSortedMeasuresData = (unsortedSeries, sortAscending) => {
  const seriesA = cloneDeep(unsortedSeries[0])
  let seriesB = cloneDeep(unsortedSeries[1])
  const resortedSeriesBData = {
    name: seriesB.name,
    data: [{
      element_id: 0,
      category: '',
      percentage: 0,
      y: 0
    }]
  }

  // first, sort series[0]
  if (sortAscending) {
    seriesA.data.sort(function (a, b) {
      return a.y - b.y
    })
  } else {
    seriesA.data.sort(function (a, b) {
      return b.y - a.y
    })
  }

  // next, rebuild series[1] according to the sorted series[0]
  seriesA.data.forEach((item, i) => {
    resortedSeriesBData.data[i] = seriesB.data.find(sbi => sbi.element_id === item.element_id)
  })

  seriesB = resortedSeriesBData
  return [seriesA, seriesB]
}

const seriesSpansYears = (start, end) => {
  const year1 = getYear(start * 1000)
  const year2 = getYear(end * 1000)
  return year1 !== year2
}

const customCalloutShape = (x, y, w, h, options) => {
  const arrowLength = 6
  const halfDistance = 6
  const r = Math.min((options && options.r) || 0, w, h)
  const path = [
    'M', x + r, y,
    'L', x + w - r, y, // top side
    'C', x + w, y, x + w, y, x + w, y + r, // top-right corner
    'L', x + w, y + h - r, // right side
    'C', x + w, y + h, x + w, y + h, x + w - r, y + h, // bottom-right corner
    'L', x + r, y + h, // bottom side
    'C', x, y + h, x, y + h, x, y + h - r, // bottom-left corner
    'L', x, y + r, // left side
    'C', x, y, x, y, x + r, y // top-right corner
  ]

  path.splice(23, 3,
    'L', w / 2 + halfDistance, y + h,
    w / 2, y + h + arrowLength,
    w / 2 - halfDistance, y + h,
    x + r, y + h
  )

  return path
}

export {
  getTickPosition,
  getColumnTickInterval,
  getDaysFromUnix,
  getTickIntervalInMilliseconds,
  getTooltipDateValue,
  // getPatientTooltipDateValue,
  getStackedColumnTooltipFormat,
  getStackedColumnTooltipPosition,
  getStackedBarTooltipFormat,
  getStackedBarTooltipPosition,
  getSortedMeasuresData,
  seriesSpansYears,
  customCalloutShape
}
