<template>
  <div class="owl-usage-donut-chart tw-p-1 tw-text-gray">
    <donut-chart
      v-if="!isLoading"
      :id="chartId"
      ref="highchartsDonut"
      :options="chartOptions"
    />
    <owl-loading-indicator v-else />
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display.js'
import { Chart } from 'highcharts-vue'
import { uniqueId } from 'lodash'
import OwlLoadingIndicator from '../LoadingIndicator.vue'

NoDataToDisplay(Highcharts)

const setTitle = (chart, seriesData, target, seriesName) => {
  const chartType = chart.userOptions.series[0].chartType
  const defaultSeries = chartTypes[chartType].defaultSeries
  const state = seriesName === defaultSeries ? 'off' : 'on'

  chart.setTitle({
    text: hoverTitle(seriesData, target, seriesName, chartType)[state],
    align: 'center',
    verticalAlign: 'middle',
    y: chartTypes[chartType].titleY,
    useHTML: true,
    style: {
      color: '#0A3560',
      textAlign: 'center'
    }
  })
}

const hoverTitle = (seriesData, target, seriesName, chartType) => {
  if (!seriesData.length) {
    return {
      on: null,
      off: null
    }
  }
  const defaultSeries = chartTypes[chartType].defaultSeries
  const onData = seriesData.find(item => item.name === seriesName)
  const offData = seriesData.find(item => item.name === defaultSeries)
  const lineOneTextOn = chartTypes[chartType].titleText[seriesName].line1
  const lineTwoTextOn = chartTypes[chartType].titleText[seriesName].line2
  const lineOneTextOff = chartTypes[chartType].titleText[defaultSeries].line1
  const lineTwoTextOff = chartTypes[chartType].titleText[defaultSeries].line2
  const targetColor = target && seriesName === defaultSeries ? '#90ED7D' : '#FFFFFF'
  const targetSnippet = '<span class="tw-text-lg tw-font-extrabold" style="color: ' + targetColor +
        '; line-height: .85em;"><i class="fas fa-bullseye"></i></span> <span class="tw-text-sm tw-leading-5 align-text-top" style="color: ' + targetColor + ';">' + target + '%</span><br/>'

  return {
    on: targetSnippet + '<span class="tw-leading-3"><span class="tw-text-6xl tw-font-black tw-ml-1 tw-tracking-tight">&nbsp;' + onData.y + '</span><span class="tw-text-3xl">%</span><br/>' + lineOneTextOn + '<br/>' + lineTwoTextOn + '</span>',
    off: targetSnippet + '<span class="tw-leading-3"><span class="tw-text-6xl tw-font-black tw-ml-1 tw-tracking-tight">' + offData.y + '</span><span class="tw-text-3xl">%</span><br/>' + lineOneTextOff + '<br/>' + lineTwoTextOff + '</span>'
  }
}

const chartTypes = {
  measureCompletionToday: {
    showLegend: false,
    defaultSeries: 'percentCompleted',
    target: 75,
    titleY: 5,
    colors: {
      percentIncomplete: '#EDBEBE',
      percentCompleted: '#90ED7D'
    },
    names: {
      percentIncomplete: 'Measures due',
      percentCompleted: 'Measures completed'
    },
    titleText: {
      percentIncomplete: {
        line1: '<span class="tw-text-base tw-leading-3">of measures are</span>',
        line2: '<span class="tw-text-base tw-leading-3">incomplete</span>'
      },
      percentCompleted: {
        line1: '<span class="tw-text-base tw-leading-3">of measures are</span>',
        line2: '<span class="tw-text-base tw-leading-3">completed</span>'
      }
    }
  },
  measureActivityToday: {
    showLegend: false,
    defaultSeries: 'percentWithMeasuresCompleted',
    target: null,
    titleY: 15,
    colors: {
      percentWithMeasuresDue: '#90ED7D',
      percentWithMeasuresCompleted: '#EDBEBE'
    },
    names: {
      percentWithMeasuresDue: 'Percent with measures due',
      percentWithMeasuresCompleted: 'Percent with measures completed'
    },
    titleText: {
      percentWithMeasuresDue: {
        line1: '<span class="tw-text-base tw-leading-3">of patient users have</span>',
        line2: '<span class="tw-text-base tw-leading-3">measures due</span>'
      },
      percentWithMeasuresCompleted: {
        line1: '<span class="tw-text-base tw-leading-3">of patient users</span>',
        line2: '<span class="tw-text-base tw-leading-3">without measures<br />due</span>'
      }
    }
  },
  activePatientsToday: {
    showLegend: false,
    defaultSeries: 'canSignInThemselves',
    target: null,
    titleY: 5,
    colors: {
      notInvitedToSignIn: '#B3C1D1',
      needInviteFollowup: '#FFF59B',
      canSignInThemselves: '#90ED7D'
    },
    names: {
      notInvitedToSignIn: 'Percent not invited to sign in',
      needInviteFollowup: 'Percent need invite follow-up',
      canSignInThemselves: 'Percent can sign in themselves'
    },
    titleText: {
      notInvitedToSignIn: {
        line1: '<span class="tw-text-base tw-leading-3">of patient users not</span>',
        line2: '<span class="tw-text-base tw-leading-3">invited to sign in<br/>themselves</span>'
      },
      needInviteFollowup: {
        line1: '<span class="tw-text-base tw-leading-3">of patient users need</span>',
        line2: '<span class="tw-text-base tw-leading-3">invite follow-up</span>'
      },
      canSignInThemselves: {
        line1: '<span class="tw-text-base tw-leading-3">of patient users can</span>',
        line2: '<span class="tw-text-base tw-leading-3">sign in themselves</span>'
      }
    }
  }
}

const getSeriesConfig = (series, chartType) => {
  return series.map((item, i) => ({
    ...item,
    data: getDataConfig(item.data, chartType),
    type: 'pie',
    showInLegend: chartTypes[chartType].showLegend,
    name: chartTypes[chartType].names[item.name],
    chartType: chartType,
    innerSize: '87%'
  }))
}

const getDataConfig = (data, chartType) => {
  return data.map((data, i) => ({
    ...data,
    color: chartTypes[chartType].colors[data.name]
  }))
}

export default {
  name: 'OwlUsageDonutChart',
  components: {
    OwlLoadingIndicator,
    donutChart: Chart
  },
  props: {
    id: {
      type: String,
      default: null
    },
    series: {
      type: Array,
      required: true
    },
    chartType: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    chartId () {
      return this.id ? this.id : uniqueId('owl-usage-donut-chart-')
    },
    chartOptions () {
      const seriesData = this.series.length ? this.series[0].data : []
      const chartType = this.chartType
      const target = chartTypes[chartType].target
      const defaultSeries = chartTypes[chartType].defaultSeries

      return {
        series: getSeriesConfig(this.series, this.chartType),
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          style: {
            fontFamily: 'inherit'
          }
        },
        title: {
          text: hoverTitle(seriesData, target, defaultSeries, chartType).off,
          align: 'center',
          verticalAlign: 'middle',
          y: chartTypes[chartType].titleY,
          useHTML: true,
          style: {
            color: '#0A3560',
            textAlign: 'center'
          }
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            },
            states: {
              hover: {
                brightness: 0
              }
            },
            startAngle: 0,
            endAngle: 360,
            center: ['50%', '50%']
          },
          series: {
            size: '95%',
            states: {
              hover: {
                halo: {
                  size: 0
                }
              }
            },
            point: {
              events: {
                mouseOver: (e) => {
                  setTitle(this.$refs.highchartsDonut.chart, this.series[0].data, target, e.target.name)
                },
                mouseOut: () => {
                  setTitle(this.$refs.highchartsDonut.chart, this.series[0].data, target, defaultSeries)
                }
              }
            }
          }
        },
        credits: {
          enabled: false
        }
      }
    }
  }
}

</script>
