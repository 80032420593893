<template>
  <div class="owl-usage-measures-bar-chart tw-font-sans">
    <bar-chart
      v-if="!isLoading"
      :id="chartId"
      :options="chartOptions"
      :callback="controls"
    />
    <owl-loading-indicator v-else />
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display.js'
import { Chart } from 'highcharts-vue'
import { uniqueId } from 'lodash'
import OwlLoadingIndicator from '../LoadingIndicator.vue'
import { getStackedBarTooltipFormat, getStackedBarTooltipPosition, getSortedMeasuresData } from '../../helpers/chartHelpers.js'

NoDataToDisplay(Highcharts)

Highcharts.setOptions({
  lang: {
    decimalPoint: '.',
    thousandsSep: ','
  }
})

const chartTypes = {
  measuresUsage: {
    title: 'Measures',
    xAxisTitle: 'MEASURE',
    yAxisTitle: 'USAGE',
    showLegend: true,
    colors: {
      measuresDue: '#DEE5ED',
      measuresCompleted: '#90ED7D'
    },
    names: {
      measuresDue: 'Due',
      measuresCompleted: 'Completed'
    },
    minPointLength: {
      measuresDue: 3,
      measuresCompleted: 0
    },
    dataLabels: {
      measuresDue: {
        color: '#8097AD',
        style: {
          fontSize: '14px'
        }
      },
      measuresCompleted: {
        inside: true,
        align: 'right',
        format: '{point.percentage}%',
        color: '#FFFFFF',
        style: {
          fontSize: '14px'
        },
        overflow: 'allow',
        filter: {
          property: 'percentage',
          operator: '>',
          value: 0
        }
      }
    }
  }
}

const getSeriesConfig = (series, chartType) =>
  series.map((item, i) => {
    return {
      ...item,
      color: chartTypes[chartType].colors[item.name],
      type: 'bar',
      showInLegend: chartTypes[chartType].showLegend,
      name: chartTypes[chartType].names[item.name],
      dataLabels: chartTypes[chartType].dataLabels[item.name],
      minPointLength: chartTypes[chartType].minPointLength[item.name]
    }
  })

export default {
  name: 'OwlUsageMeasuresBarChart',
  components: {
    OwlLoadingIndicator,
    barChart: Chart
  },
  props: {
    id: {
      type: String,
      default: null
    },
    chartType: {
      type: String,
      default: 'measuresUsage'
    },
    series: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sortAscending: false,
      sortArrow: {
        asc: '<i class="fas fa-arrow-up"></i>',
        desc: '<i class="fas fa-arrow-down"></i>'
      }
    }
  },
  computed: {
    chartId () {
      return this.id ? this.id : uniqueId('owl-usage-measures-bar-chart-')
    },
    chartOptions () {
      return {
        series: getSeriesConfig(this.sortedSeries, this.chartType),
        chart: {
          animation: false,
          marginLeft: 256,
          marginRight: 50,
          style: {
            fontFamily: 'inherit'
          },
          events: {
            load: function () {
              const categoryHeight = 20
              this.update({
                chart: {
                  height: categoryHeight * this.pointCount + (this.chartHeight - this.plotHeight)
                }
              })
            }
          }
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          symbolRadius: 0,
          x: -50,
          itemStyle: {
            fontSize: '14px',
            fontWeight: 'normal',
            color: '#0A3560'
          }
        },
        plotOptions: {
          series: {
            pointWidth: 20,
            grouping: false,
            dataLabels: {
              enabled: true,
              style: {
                textOutline: 'none',
                fontWeight: 'normal'
              }
            }
          }
        },
        xAxis: [
          {
            type: 'category',
            categories: this.categories,
            lineWidth: 0,
            labels: {
              style: {
                color: '#0A3560',
                fontSize: '14px'
              }
            },
            title: {
              align: 'high',
              offset: 0,
              rotation: 0,
              text: chartTypes[this.chartType].xAxisTitle,
              y: -20,
              x: -14,
              style: {
                fontSize: '12px',
                fontWeight: 'normal',
                color: '#0A3560'
              }
            }
          }
        ],
        yAxis: {
          visible: false
        },
        title: {
          text: null
        },
        tooltip: {
          formatter: function () {
            return getStackedBarTooltipFormat(this)
          },
          positioner: function (width, height, point) {
            return getStackedBarTooltipPosition(this, width, height, point)
          }
        },
        credits: {
          enabled: false
        }
      }
    },
    sortDirection () {
      return this.sortAscending ? 'asc' : 'desc'
    },
    sortedSeries () {
      return getSortedMeasuresData(this.series, this.sortAscending)
    },
    categories () {
      return this.sortedSeries[0].data.map(item => item.category)
    }
  },
  methods: {
    controls (chart) {
      this.renderButton(chart)
    },
    renderButton (chart) {
      /**
       * Adds a custom button to allow for series sorting within the plot area
       */

      const theme = {
        fill: '#FFFFFF',
        stroke: '#FFFFFF',
        padding: 0,
        margin: 0,
        height: 16,
        style: {
          color: '#0A3560'
        }
      }

      const callback = () => {
        this.sortAscending = !this.sortAscending
        this.updateButtonValue(chart)
      }

      this.sortButton = chart.renderer.button(
        this.getSortButtonText(this.sortDirection),
        256,
        19,
        callback,
        theme,
        theme,
        theme,
        theme,
        null,
        true
      ).add()
    },
    getSortButtonText (sortDirection) {
      return 'USAGE ' + this.sortArrow[sortDirection]
    },
    updateButtonValue (chart) {
      this.sortButton.text.destroy()
      this.renderButton(chart)
    }
  }
}
</script>
