<template>
  <div class="owl-usage-clinical-column-chart tw-font-sans">
    <column-chart
      v-if="!isLoading"
      :id="chartId"
      :options="chartOptions"
    />
    <owl-loading-indicator v-else />
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display.js'
import { Chart } from 'highcharts-vue'
import { uniqueId } from 'lodash'
import OwlLoadingIndicator from '../LoadingIndicator.vue'
import {
  getTickAndGraphIntervalFromUnix,
  getTickIntervalInMilliseconds,
  getStackedColumnTooltipFormat,
  seriesSpansYears
} from '../../helpers/chartHelpers.js'

NoDataToDisplay(Highcharts)

const chartTypes = {
  clinicalSignins: {
    title: 'Clinical User Sign-ins',
    yAxisTitle: 'Clinical users who signed in',
    showLegend: true,
    colors: {
      signinsClinicians: '#28AfE5',
      signinsSupervisors: '#F57723',
      signinsStaff: '#8097AD'
    },
    names: {
      signinsClinicians: 'Clinicians',
      signinsSupervisors: 'Supervisors',
      signinsStaff: 'Staff & Admins'
    },
    dataLabels: {
      signinsClinicians: {
        color: '#8097AD',
        style: {
          fontSize: '14px'
        }
      },
      signinsSupervisors: {
        inside: true,
        align: 'right',
        color: '#FFFFFF',
        style: {
          fontSize: '14px'
        }
      },
      signinsStaff: {
        color: '#8097AD',
        style: {
          fontSize: '14px'
        }
      }
    }
  }
}

const getSeriesConfig = (series, chartType, hasErrors) => {
  return hasErrors
    ? []
    : series.map((item) => ({
      ...item,
      color: chartTypes[chartType].colors[item.name],
      type: 'column',
      showInLegend: chartTypes[chartType].showLegend,
      name: chartTypes[chartType].names[item.name],
      dataLabels: chartTypes[chartType].dataLabels[item.name]
    }))
}

export default {
  name: 'OwlUsageClinicalColumnChart',
  components: {
    OwlLoadingIndicator,
    columnChart: Chart
  },
  props: {
    id: {
      type: String,
      default: null
    },
    chartType: {
      type: String,
      default: 'clinicalSignins'
    },
    series: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hasErrors: {
      type: Boolean,
      default: false
    },
    start: {
      type: Number,
      required: true
    },
    end: {
      type: Number,
      required: true
    }
  },
  computed: {
    chartId () {
      return this.id ? this.id : uniqueId('owl-usage-clinical-column-chart-')
    },
    showYear () {
      return seriesSpansYears(this.start, this.end)
    },
    pointInterval () {
      return getTickAndGraphIntervalFromUnix(this.start, this.end).points
    },
    tickInterval () {
      return getTickAndGraphIntervalFromUnix(this.start, this.end).ticks
    },
    chartOptions () {
      const tickInterval = this.tickInterval
      const start = this.start
      const end = this.end

      return {
        series: getSeriesConfig(this.series, this.chartType, this.hasErrors),
        chart: {
          animation: false,
          marginLeft: 80,
          marginRight: 50,
          style: {
            fontFamily: 'inherit'
          }
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          itemStyle: {
            fontSize: '14px',
            fontWeight: 'normal'
          },
          labelFormatter: function () {
            return '<span style="color: ' + this.color + '">' + this.name + '</span>'
          },
          symbolPadding: 0,
          symbolWidth: 0,
          symbolHeight: 0,
          squareSymbol: false
        },
        plotOptions: {
          series: {
            maxPointWidth: 20,
            stacking: 'normal',
            dataLabels: {
              enabled: false,
              style: {
                textOutline: 'none',
                fontWeight: 'normal'
              }
            }
          }
        },
        lang: {
          noData: this.hasErrors ? 'Sorry, but there was a problem generating this chart.' : 'No data found'
        },
        noData: {
          style: {
            fontWeight: 'normal'
          }
        },
        xAxis: {
          type: 'datetime',
          startOfWeek: 0,
          labels: {
            y: 22,
            align: 'center',
            formatter: (hc) => {
              const dayFormatString = tickInterval === 'bi-weekly' || tickInterval === 'weekly' || tickInterval === 'daily' ? '%a ' : ''
              const dateFormatString = this.showYear ? '%b %e<br/>%Y' : '%b %e'
              return Highcharts.dateFormat(`<span class="tw-uppercase">${dayFormatString}</span><br/><span class="tw-uppercase">${dateFormatString}</span>`, hc.value)
            }
          },
          tickInterval: getTickIntervalInMilliseconds(tickInterval)
        },
        yAxis: [
          {
            lineWidth: 0,
            labels: {
              style: {
                color: '#0A3560',
                fontSize: '14px'
              }
            },
            title: {
              margin: 16,
              text: chartTypes[this.chartType].yAxisTitle,
              style: {
                color: '#0A3560',
                fontSize: '14px',
                fontWeight: 'normal'
              }
            }
          }
        ],
        tooltip: {
          shared: true,
          followPointer: true,
          hideDelay: 0,
          style: {
            fontSize: '16px'
          },
          formatter: function () {
            return getStackedColumnTooltipFormat(this, start, end)
          }
        },
        title: {
          margin: 30,
          text: chartTypes[this.chartType].title,
          align: 'left',
          style: {
            fontSize: '20px'
          }
        },
        credits: {
          enabled: false
        }
      }
    }
  }
}
</script>
