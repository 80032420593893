<template>
  <button
    class="tw-border"
    :class="[variantClasses, sizeClasses, { 'tw-w-full' : isBlock }]"
    :disabled="disabled"
    type="button"
    @click="onClick"
  >
    <slot>
      {{ label }}
    </slot>
  </button>
</template>

<script>
export default {
  name: 'OwlButton',
  props: {
    label: {
      type: String,
      default: null
    },
    isBlock: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'medium'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    variantClasses () {
      switch (this.variant) {
        case 'primary':
          return 'tw-bg-owl-gray-link tw-text-white tw-border-owl-gray-link hover:tw-text-owl-gray-link hover:tw-bg-owl-teal-light'
        case 'secondary':
          return 'tw-bg-owl-gray-lightest tw-text-owl-gray-link tw-border-owl-gray-border hover:tw-bg-owl-teal-light'
        case 'text':
          return 'tw-border-none tw-text-owl-navy'
        case 'disabled':
          return 'tw-bg-owl-gray-canvas tw-text-owl-gray-border tw-border-owl-gray-border' // TODO cleanup tailwind color definition names to be less descriptive
        default: // primary
          return 'tw-bg-owl-gray-lightest tw-text-black tw-border-owl-navy'
      }
    },
    sizeClasses () {
      switch (this.size) {
        case 'tiny':
          return 'tw-py-1 tw-px-2'
        case 'small':
          return 'tw-py-1 tw-px-2 tw-rounded'
        case 'large':
          return 'tw-py-1 tw-px-2 tw-rounded-lg'
        case 'xl':
          return 'tw-py-1 tw-px-1 tw-rounded-lg'
        default: // medium
          return 'tw-py-1 tw-px-2 tw-rounded'
      }
    }
  },
  methods: {
    onClick (event) {
      this.$emit('on-click')
    }
  }
}
</script>
