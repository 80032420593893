<template>
  <div class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-text-gray-400 tw-font-sans">
    <div>
      <i class="fas fa-spinner-third fa-spin" /> Loading...
    </div>
  </div>
</template>

<script>
export default {
  name: 'OwlLoadingIndicator'
}
</script>
