<template>
  <div class="owl-useage-patients-line-chart tw-font-sans tw-h-full">
    <line-chart
      v-if="!isLoading"
      :id="chartId"
      :options="chartOptions"
    />
    <owl-loading-indicator v-else />
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display.js'
import { Chart } from 'highcharts-vue'
import { uniqueId } from 'lodash'
import OwlLoadingIndicator from '../LoadingIndicator.vue'
import { getTickAndGraphIntervalFromUnix, getTooltipDateValue, getTickIntervalInMilliseconds, seriesSpansYears } from '../../helpers/chartHelpers.js'
import { differenceInCalendarDays, fromUnixTime } from 'date-fns'

Highcharts.setOptions({
  global: {
    useUTC: false
  }
})

NoDataToDisplay(Highcharts)

const chartTypes = {
  measureActivity: {
    title: 'Measure Activity',
    yAxisTitle: 'Measure activity',
    yAxisSymbol: '',
    yAxisMin: null,
    yAxisMax: null,
    endOnTick: true,
    showLegend: true,
    colors: {
      measuresDue: '#28AFE5',
      measuresCompleted: '#7ED321'
    },
    names: {
      measuresDue: 'Measures due',
      measuresCompleted: 'Measures completed'
    },
    tooltipText: {
      measuresDue: '',
      measuresCompleted: ''
    },
    pointPlacement: {
      measuresDue: 0,
      measuresCompleted: 0.1
    },
    target: null,
    tooltipFormatter: (pointData, start, end) => `<div class="tw-font-bold">${getTooltipDateValue(pointData, start, end, 'backward')}:</div>
      ${pointData.series.name}: ${pointData.y}`
  },
  activePatients: {
    title: 'Active Patient Users',
    yAxisTitle: 'Patient users',
    yAxisSymbol: '',
    yAxisMin: 0,
    yAxisMax: null,
    endOnTick: true,
    showLegend: true,
    colors: {
      totalActive: '#F57723',
      new: '#28AFE5'
    },
    names: {
      totalActive: 'Total active',
      new: 'New'
    },
    tooltipText: {
      totalActive: 'active patient users.',
      new: 'new patient users added.'
    },
    pointPlacement: {
      totalActive: 0,
      new: 0.1
    },
    target: null,
    tooltipFormatter: (pointData, start, end) => {
      return `<div class="tw-font-bold">${getTooltipDateValue(pointData, start, end, 'backward')}:</div>
      ${pointData.y} ${pointData.series.userOptions.customTooltipText}`
    }
  },
  measureCompletionRate: {
    title: 'Measure Completion Rate',
    yAxisTitle: 'Measure completion rate',
    yAxisSymbol: '%',
    yAxisMin: 0,
    yAxisMax: 115,
    endOnTick: false,
    showLegend: false,
    colors: {
      measureCompletionRate: '#28AFE5'
    },
    names: {
      measureCompletionRate: 'Measure completion rate'
    },
    tooltipText: {
      measureCompletionRate: ''
    },
    pointPlacement: {
      measureCompletionRate: 0
    },
    target: 75,
    tooltipFormatter: (pointData, start, end) => `
      <div class="tw-font-bold">${getTooltipDateValue(pointData, start, end, 'backward')}:</div>
      ${pointData.y}% of measures due were completed.
    `
  }
}

// might make sense to define a chart type and have the container send less things like labels and colors?
// then you can map a prop with vars here to define color and things... ¯\_(ツ)_/¯
// this is our first one, we can keep it simple and "just" for usage charts for this round

const getYAxis = (chartType) => {
  return {
    title: {
      text: chartTypes[chartType].yAxisTitle,
      margin: 20,
      style: {
        fontSize: 14,
        color: '#0A3560',
        fontWeight: 200
      }
    },
    labels: {
      x: -5,
      style: {
        color: '#0A3560',
        fontWeight: 200
      },
      formatter: function () {
        return this.value + chartTypes[chartType].yAxisSymbol
      }
    },
    max: chartTypes[chartType].yAxisMax,
    min: chartTypes[chartType].yAxisMin,
    endOnTick: chartTypes[chartType].endOnTick,
    // eslint-disable-next-line multiline-ternary
    plotLines: chartTypes[chartType].target ? [
      {
        // hard code the colors here, try to restrain how much configuration we offer (as much as possible)
        color: '#7F97AD', // Color value
        dashStyle: 'LongDash', // Style of the plot line. Default to solid
        value: chartTypes[chartType].target, // Value of where the line will appear
        width: 1.5, // Width of the line
        label: {
          text: '<span>⦾</span>',
          useHTML: true,
          color: '#8097AD',
          align: 'right',
          y: -5,
          x: 5,
          style: {
            fontSize: '24px',
            color: '#8097AD'
          }
        }
      }
    ] : []
  }
}

const getSeriesConfig = (series, chartType, pointInterval, hasErrors) => {
  return hasErrors
    ? []
    : series.map((item, i) => ({
      ...item,
      color: chartTypes[chartType].colors[item.name],
      type: 'line',
      showInLegend: chartTypes[chartType].showLegend,
      name: chartTypes[chartType].names[item.name],
      pointInterval: pointInterval,
      customTooltipText: chartTypes[chartType].tooltipText[item.name],
      pointPlacement: chartTypes[chartType].pointPlacement[item.name],

      dataLabels: {
        enabled: false,
        allowOverlap: false,
        useHTML: true,
        formatter: function () {
          return `<div class="tw-absolute tw-bg-white tw--translate-x-1/2 tw--translate-y-2 tw-justify-center tw-items-center tw-text-xs tw-font-normal tw-flex tw-border-2 tw-rounded-full tw-min-w-6 tw-h-6 tw-p-1" style="border-color: ${chartTypes[chartType].colors[item.name]}">
          ${this.point.y}
        </div>`
        }
      },

      point: {
        events: {
          mouseOut: function () {
            this.update({
              dataLabels: {
                enabled: false
              }
            })
          },
          mouseOver: function () {
            this.update({
              dataLabels: {
                enabled: true
              }
            })
          }
        }
      }
    }))
}

export default {
  name: 'OwlUsagePatientsLineChart',
  components: {
    OwlLoadingIndicator,
    lineChart: Chart
  },
  props: {
    id: {
      type: String,
      default: null
    },
    series: {
      type: Array,
      required: true
    },
    chartType: {
      type: String,
      required: true
    },
    hasErrors: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    start: {
      type: Number,
      required: true
    },
    end: {
      type: Number,
      required: true
    }
  },
  computed: {
    showPointMarkers () {
      const days = differenceInCalendarDays(fromUnixTime(this.end), fromUnixTime(this.start))
      return this.pointInterval === 'daily' && days < 32
    },
    chartId () {
      return this.id ? this.id : uniqueId('owl-usage-patients-line-chart-')
    },
    pointInterval () {
      return getTickAndGraphIntervalFromUnix(this.start, this.end).patientUsagePoints
    },
    tickInterval () {
      return getTickAndGraphIntervalFromUnix(this.start, this.end).ticks
    },
    showYear () {
      return seriesSpansYears(this.start, this.end)
    },
    chartOptions () {
      const chartType = this.chartType
      const pointInterval = this.pointInterval
      const tickInterval = this.tickInterval
      const start = this.start
      const end = this.end
      const showYear = this.showYear

      return {
        series: getSeriesConfig(this.series, chartType, pointInterval, this.hasErrors),
        chart: {
          animation: false,
          marginLeft: 80,
          marginRight: 50,
          style: {
            fontFamily: 'inherit'
          }
        },
        plotOptions: {
          series: {
            marker: {
              // enabled: this.showPointMarkers,
              enabled: true,
              symbol: 'circle',
              radius: 3
            },
            stickyTracking: false
          }
        },
        lang: {
          noData: this.hasErrors ? 'Sorry, but there was a problem generating this chart.' : 'No data found'
        },
        noData: {
          style: {
            fontWeight: 'normal'
          }
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          labelFormatter: function () {
            return (
              '<span style="color: ' + this.color + '">' + this.name + '</span>'
            )
          },
          x: -37,
          y: -10,
          itemStyle: {
            fontSize: '14px',
            fontWeight: 200,
            color: '#0A3560'
          }
        },
        xAxis: [
          {
            type: 'datetime',
            showFirstLabel: true,
            showLastLabel: true,
            margin: 100,
            // maxPadding: 0.5,
            startOfWeek: 0,
            tickInterval: getTickIntervalInMilliseconds(tickInterval),
            labels: {
              style: {
                color: '#0A3560',
                fontWeight: 200
              },
              y: 22,
              formatter: function () {
                const dayFormat = tickInterval === 'bi-weekly' || tickInterval === 'weekly' || tickInterval === 'daily' ? '%a ' : ''
                const dateFormat = (tickInterval === 'quarterly' || tickInterval === 'semi-annually' || showYear) ? '%b %e, %Y' : `${dayFormat} %b %e`
                return Highcharts.dateFormat(dateFormat, this.value)
              }
            }
          }
        ],
        yAxis: getYAxis(this.chartType),
        title: {
          text: chartTypes[this.chartType].title,
          align: 'left',
          verticalAlign: 'top',
          x: 0,
          style: {
            fontSize: '20',
            fontWeight: 500,
            color: '#0A3560'
          }
        },
        tooltip: {
          backgroundColor: '#FFFFFF',
          borderWidth: 1,
          distance: 22,
          style: {
            fontSize: '16px'
          },
          useHTML: true,
          formatter: function () {
            return chartTypes[chartType].tooltipFormatter(this, start, end)
          }
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        credits: {
          enabled: false
        }
      }
    }
  }
}
</script>
