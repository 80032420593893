<template>
  <ul
    v-if="options.length > 0"
    class="tw-border tw-border-owl-navy"
  >
    <li
      v-for="(option, i) in options"
      :key="i"
      class="tw-w-full"
    >
      <button
        :ref="`option-item-${i}`"
        class="tw-w-full tw-text-left tw-px-3.5 tw-py-1 hover:tw-bg-owl-teal-lightest"
        :class="{ readonly: option.isHeader, selected: optionSelected(option) }"
        :disabled="option.isHeader"
        @click="selectItem(option)"
        @keydown.up="focusOn(i - 1)"
        @keydown.down="focusOn(i + 1)"
      >
        <span :title="option.name">{{ option.name }}</span>
      </button>
    </li>
  </ul>
  <ul
    v-else
    class="tw-border tw-border-owl-navy"
  >
    <li class="tw-w-full">
      <button
        class="tw-w-full tw-text-left tw-px-3.5 tw-py-1 hover:tw-bg-owl-teal-lightest readonly"
        :disabled="true"
      >
        <span>No items to select!</span>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'OwlOption',
  props: {
    selected: {
      type: Object,
      default: () => {}
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    options () {
      return this.items
    }
  },
  methods: {
    focusOn (i) {
      const item = `option-item-${i}`

      if (this.$refs[item] && this.$refs[item][0]) {
        this.$refs[item][0].focus()
      }
    },
    selectItem (item) {
      this.$emit('toggle-menu')
      if (!this.optionSelected(item)) {
        this.$emit('update-selected', item)
      }
    },
    optionSelected (option) {
      return option.name === this.selected.name
    }
  }
}
</script>

<style lang="scss" scoped>
  ul {
    @apply tw-text-owl-navy tw-shadow-md tw-mb-0;
    max-height: 400px;
    overflow-y: overlay;
  }
  li button {
    @apply tw-h-10;
    span {
      @apply tw-text-sm;
      max-width: 490px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .readonly {
    font-family: 'MuseoSans_300';
    @apply tw-h-8 tw-text-xs tw-cursor-default tw-pointer-events-none tw-font-light tw-uppercase tw-bg-owl-gray-canvas tw-border-b tw-border-owl-gray-border;
  }
  .selected {
    @apply tw-cursor-default tw-bg-owl-gray-lightest;
  }
</style>
