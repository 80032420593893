<template>
  <button
    class="tw-min-w-20 tw-h-7 tw-text-owl-gray-link tw-border-owl-gray-border tw-border tw-mt-2 tw-mr-2.5 tw-py-1 tw-px-4 tw-rounded-md tw-text-sm tw-inline-block"
    :class="chipState"
    :disabled="isDisabled"
    @click="onClick"
  >
    <slot>
      <span class="tw-flex tw-justify-center tw-select-none">{{ label }}</span>
    </slot>
  </button>
</template>

<script>
export default {
  name: 'OwlChip',
  props: {
    label: {
      type: String,
      default: null
    },
    state: {
      type: String,
      default: 'unselected'
    }
  },
  computed: {
    chipState () {
      switch (this.state) {
        case 'selected':
          return 'tw-owl-gray-border tw-bg-owl-teal-light tw-pt-1 cursor-default'
        case 'disabled':
          return 'tw-bg-white tw-text-owl-gray-border tw-cursor-not-allowed'
        default: // unselected
          return 'tw-bg-owl-gray-lightest hover:tw-bg-owl-teal-lightest tw-cursor-pointer'
      }
    },
    isDisabled () {
      return this.state === 'disabled'
    }
  },
  methods: {
    onClick (e) {
      this.$emit('on-click')
    }
  }
}
</script>
