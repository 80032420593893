<template>
  <owl-on-click-outside
    :do="closeMenu"
    @keydown.esc="closeMenu"
  >
    <div class="owl-dropdown tw-inline-block tw-relative">
      <owl-button
        :label="buttonLabelData"
        :size="size"
        :disabled="disabled"
        :variant="variant"
        :class="[sizeClasses]"
        @on-click="toggleMenu"
        @keydown.esc="closeMenu"
      />
      <span class="dropdown-arrow tw-align-middle tw-text-owl-navy">
        <i
          class="fal"
          :class="[toggleStateClass, sizeClasses]"
        />
      </span>
      <div
        v-if="open"
        ref="menu"
        class="tw-border tw-mt-1 tw-absolute tw-bg-white tw-shadow"
        :class="[ menuAlignmentClass ]"
        @keydown.esc="closeMenu"
      >
        <slot
          :toggleMenu="toggleMenu"
          :closeMenu="closeMenu"
          :open="open"
        >
          Empty Slot
        </slot>
      </div>
    </div>
  </owl-on-click-outside>
</template>

<script>
import OwlButton from './Button.vue'
import OwlOnClickOutside from './OnClickOutside.vue'

export default {
  name: 'OwlButtonDropdown',
  components: {
    OwlButton,
    OwlOnClickOutside
  },
  props: {
    buttonLabel: {
      type: String,
      default: 'Open'
    },
    menuAlignment: {
      type: String,
      default: 'left'
    },
    size: {
      type: String,
      default: 'large'
    },
    variant: {
      type: String,
      default: 'text'
    },
    selected: {
      type: Object,
      default: () => {}
    },
    autoToggle: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      open: false,
      buttonLabelData: this.buttonLabel
    }
  },
  computed: {
    menuAlignmentClass () {
      return this.menuAlignment === 'right' ? 'tw-right-0' : ''
    },
    toggleStateClass () {
      return this.open ? 'fa-angle-up' : 'fa-angle-down'
    },
    sizeClasses () {
      switch (this.size) {
        case 'tiny':
          return 'tw-text-xs tw-my-2'
        case 'small':
          return 'tw-text-sm tw-my-3'
        case 'large':
          return 'tw-text-lg tw-my-4'
        case 'xl':
          return 'tw-text-xxl'
        default: // medium
          return 'tw-text-base tw-my-3.5'
      }
    }
  },
  watch: {
    selected: {
      handler (newValue) {
        if (this.autoToggle) {
          this.toggleMenu()
        }
        this.buttonLabelData = this.selected.name
      }
    }
  },
  methods: {
    emitMenuStatus () {
      this.$emit('update-menu-status', this.open)
    },
    toggleMenu () {
      this.open = !this.open

      if (this.open) {
        this.focusNextChild()
      }

      this.emitMenuStatus()
    },
    closeMenu () {
      this.open = false
      this.emitMenuStatus()
    },
    focusNextChild () {
      if (this.open) {
        this.$nextTick(function () {
          const focusable = this.$refs.menu.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
          if (focusable[0]) {
            focusable[0].focus()
          }
        })
      }
    }
  }
}
</script>
