<template>
  <div class="owl-date-picker tw-font-sans">
    <date-picker
      ref="datePicker"
      mode="date"
      timezone="UTC"
      :value="date"
      :model-config="modelConfig"
      :min-date="minDate"
      :max-date="maxDate"
      :is-required="isRequired"
      @input="dateUpdated"
    />
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

const DATE_FORMAT = 'YYYY-MM-DD'

export default {
  name: 'OwlDatePicker',
  components: {
    DatePicker
  },
  props: {
    maxDate: {
      type: String,
      default: null
    },
    minDate: {
      type: String,
      default: null
    },
    date: {
      type: String,
      default: null
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    dateFormat: {
      type: String,
      default: DATE_FORMAT
    }
  },
  computed: {
    modelConfig () {
      return {
        type: 'string',
        mask: this.dateFormat,
        timeAdjust: '12:00:00'
      }
    }
  },
  watch: {
    date: {
      immediate: true,
      handler () {
        this.$nextTick(() => {
          if (this.date) {
            const [year, month, day] = this.date.split('-')
            this.$refs.datePicker.focusDate({
              day: parseInt(day),
              month: parseInt(month),
              year: parseInt(year)
            })
          }
        })
      }
    }
  },
  methods: {
    dateUpdated (value) {
      this.$emit('update', value)
      this.$emit('endDateSelected')
    }
  }
}
</script>
